import FilterDropdown from "./FilterDropdown"
import { BREWERYTYPES, COUNTRIES } from "../misc/constants"

function Filter() {
  return (
    <div className="self-start flex gap-8 my-8">
      <FilterDropdown title="By Type" options={BREWERYTYPES} />
      <FilterDropdown title="By Country" options={COUNTRIES} />
    </div>
  )
}

export default Filter