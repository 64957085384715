import { useEffect, useState } from "react";
import axios from "axios";

import { Data } from "../misc/types";
import { BASEURL } from "../misc/constants";

import { useQuery } from "./queryContext";

/*
This file has 3 functions:
1. useFetchMeta: this function is for fetching meta data, so I can know how many entries we have, the nI can calculate how many pages we have, so  Ican apply the pagination feature.
2. useFetchBreweries: this is a general function to fetch data list according to the queries, when query is empty, it fetches all the data
3. useFetchBreweryById: this is only for detailed info, returns an object.
*/

function useFetchMeta () {
  const [meta, setMeta] = useState<Data>({});
  const [error, setError] = useState(null);

  const {queryValue} = useQuery();
  let queryStr =  '';
  if (queryValue) {
    queryStr = new URLSearchParams(queryValue).toString();
    queryStr = `?${queryStr}`;
  }

  useEffect(() => {
    axios
      .get(BASEURL + "/meta" + queryStr)
      .then((res) => {
        // console.log(res.data);
        setMeta(res.data);
      })
      .catch((err) => {
        setError(err);
      });
  }, [queryStr]);

  return { meta, error };
}

function useFetchBreweries() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {queryValue} = useQuery();

  // console.log(queryValue);
  let queryStr =  '';
  if (queryValue) {
    queryStr = new URLSearchParams(queryValue).toString();
    queryStr = `?${queryStr}`;
  }

  useEffect(() => {
    axios
      .get(BASEURL+ queryStr)
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [queryStr]);

  return { data, loading, error };
}

function useFetchBreweryById({id }: {id: string | undefined}) {
  const [data, setData] = useState<Data | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(BASEURL+`/${id}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [id]);

  return { data, loading, error };
}

export  {useFetchMeta, useFetchBreweries, useFetchBreweryById};
