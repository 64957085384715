import type { Data } from "../misc/types";
import { Link } from "react-router-dom";

function Table({ data }: { data: Data[] }) {

  return (
    <>
      <table className="w-full table-fixed text-sm text-left bg-white shadow-xl rounded-xl">
        <thead className="h-16">
          <tr>
            <th className="w-3/12 px-4">Brewery name</th>
            <th className="w-1/12">Type</th>
            <th className="hidden md:table-cell w-3/12">Street</th>
            <th>City</th>
            <th>State/Province</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {data.map((brewery) => (
            <tr key={brewery.id} onClick={()=> {window.open(`/brewery/${brewery.id}`, '_blank')}} className="h-12 border-t hover:cursor-pointer duration-100 odd:bg-gray-100 even:bg-white hover:border-2 hover:border-red-700">
              <td className="px-4">
                  {brewery.name}
              </td>
              <td>{brewery.brewery_type}</td>
              <td className="hidden md:table-cell">{brewery.street}</td>
              <td>{brewery.city}</td>
              <td>{brewery.state_province}</td>
              <td>{brewery.country}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default Table;
