import { Link } from "react-router-dom"

function ErrorPage() {
  return (
    <div className="flex flex-col justufy-center items-center py-20">
        <h1 className="text-3xl text-red-700 my-8">Oops! You are in a desert where has no brewery!</h1>
        <Link className="bg-yellow-400 px-4 py-2 rounded-lg hover:bg-yellow-500" to="/">Click here to to home page</Link>
    </div>
  )
}

export default ErrorPage