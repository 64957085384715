import { useParams } from "react-router-dom";
import { useFetchBreweryById } from "../hooks/useFetchBreweries";

import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ErrorPage from "./ErrorPage";

function Single() {
  const { id } = useParams();

  const { data: brewery, loading, error } = useFetchBreweryById({ id: id });

  if (error) {
    return <ErrorPage />;
  }

  return (
    <div className="flex justify-center p-12">
      <div className="bg-white w-5/12 p-8 rounded-xl shadow-xl">
        {loading ? (
          <Loading />
        ) : brewery ? (
          <div className="flex flex-col justify-center">
            <table className="table-auto">
              <caption className="text-xl my-4 pb-4 border-b">
                {brewery.name}
              </caption>
              <tbody className="text-left *:h-10">
                <tr>
                  <th>Brewery Type</th>
                  <td>{brewery.brewery_type}</td>
                </tr>
                <tr>
                  <th>Address:</th>
                  <td>{brewery.street}</td>
                </tr>
                <tr>
                  <th>City:</th>
                  <td>{brewery.city}</td>
                </tr>
                <tr>
                  <th>State/Province:</th>
                  <td>{brewery.state_province}</td>
                </tr>
                <tr>
                  <th>Postal Code:</th>
                  <td>{brewery.postal_code}</td>
                </tr>
                <tr>
                  <th>Country:</th>
                  <td>{brewery.country}</td>
                </tr>
                <tr>
                  <th>Phone:</th>
                  <td>{brewery.phone}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-center gap-4 mt-8">
              <Link
                className={`rounded-xl px-4 py-2 text-white ${
                  brewery.website_url
                    ? "bg-red-500 hover:bg-red-800 duration-500 "
                    : "pointer-events-none bg-gray-500"
                }`}
                to={brewery.website_url}
                target="_blank"
              >
                Visit Website
              </Link>
              <Link
                className={`rounded-xl px-4 py-2 text-white ${
                  brewery.latitude && brewery.longitude
                    ? "bg-red-500 hover:bg-red-800 duration-500 "
                    : "pointer-events-none bg-gray-500"
                }`}
                to={`https://www.google.com/maps/search/?api=1&query=${brewery.latitude}%2C${brewery.longitude}`}
                target="_blank"
              >
                Google map
              </Link>
            </div>
          </div>
        ) : (
          <p>Here does not have a brewery!</p>
        )}
      </div>
    </div>
  );
}

export default Single;
