import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../hooks/queryContext";

const debounce = require("lodash.debounce");

function Search() {
  const [input, setInput] = useState("");
  const { setQueryValue } = useQuery();
  const navagate = useNavigate();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSearch = () => {
    if (setQueryValue) {
      setQueryValue({ by_name: input });
      navagate("/")
    }
    // setInput("");
  };

  // if press enter key, it will return the search result immediately
  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // if dont do anything, it will return result after stopp typing for 1s
  const debounced = useCallback(debounce(handleSearch, 1000),[input])
  useEffect(() => {
    if(input) debounced();
    // clean up the function every time when input changed, otherwise error will happen when I quickly delete the input
    // it took me a while to find out the reason >_<!
    return () => {
      debounced.cancel()
    }
  },[input, debounced])


  return (
    <div className="flex justify-between items-center gap-4">
      <input
        name="search"
        className="px-2 py-1 rounded-xl outline-none"
        type="text"
        placeholder="name of a brewery"
        onChange={handleInput}
        value={input}
        onKeyDown={handleEnterKey}
      />
      <button onClick={handleSearch} aria-label="Search button">
        <svg
          className="w-6 h-6 stroke-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
        </svg>
      </button>
    </div>
  );
}

export default Search;
