import { useForm, SubmitHandler } from "react-hook-form";
import type { Form } from "../misc/types";
// Thanks react-hook-form, it saved tons of codes!!!!!

function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = (formData) =>
    alert(`
    Hi, ${formData.username}, we received (actually not) your feedback: \n
    ${formData.feedback}
    `);

  return (
    <div
      className="mt-20 mb-12 flex flex-col justify-center items-center"
      style={{ minHeight: "600px", width:"500px" }}
    >
      <h2 className="text-5xl font-bold text-red-900">Get in Touch</h2>
      <form
        className="bg-white mt-8 w-full p-4 flex flex-col "
        onSubmit={handleSubmit(onSubmit)}
      >
        <label htmlFor="username" className="form-label">
          Your name*
        </label>
        <input
          id="username"
          type="text"
          className="form-input"
          autoComplete="true"
          required
          {...register("username", {
            required: true,
            maxLength: 20,
            minLength: 3,
          })}
        />
        <p className={`form-error ${errors.username ? "" : "hidden"}`}>
          Name is too short!
        </p>

        <label htmlFor="email" className="form-label">
          Your Email*
        </label>
        <input
          id="email"
          type="email"
          className="form-input"
          placeholder="john@example.com"
          autoComplete="true"
          required
          {...register("email", {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          })}
        />
        <p className={`form-error ${errors.email ? "" : "hidden"}`}>
          Email is not valid!
        </p>

        <label htmlFor="phone" className="form-label">
          Your phone number
        </label>
        <input
          id="phone"
          type="tel"
          className="form-input"
          placeholder="+358 4444444"
          autoComplete="true"
          {...register("phone")}
        />

        <label htmlFor="feedback" className="form-label">
          Feedback*
        </label>
        <textarea
          id="feedback"
          maxLength={500}
          className="form-input"
          placeholder="Please write down you feedback here ..."
          required
          {...register("feedback", { required: true, minLength: 10 })}
        ></textarea>
          <p className={`form-error ${errors.feedback ? "" : "hidden"}`}>
            Feedback must be longer than 10 charaters!
          </p>

        <p className="text-sm text-gray-700">*This is a required field</p>
        <button
          type="submit"
          aria-label="Send Feedback"
          className="btn-primary self-center"
        >
          Send Feedback
        </button>
      </form>
    </div>
  );
}

export default Contact;
