export const BASEURL = "https://api.openbrewerydb.org/v1/breweries";

export const BREWERYTYPES = [
  "micro",
  "nano",
  "regional",
  "brewpub",
  "large",
  "planning",
  "bar",
  "contract",
  "proprietor",
  "closed",
];

export const COUNTRIES = [
  "Austria",
  "England",
  "France",
  "Isle of Man",
  "Ireland",
  "Poland",
  "Portugal",
  "Scotland",
  "South Korea",
  "United States",
];
