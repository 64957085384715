import { FilterDropdownProps} from "../misc/types";
import { useQuery } from "../hooks/queryContext";

function FilterDropdown({ title, options }: FilterDropdownProps) {
  const {setQueryValue} = useQuery();

  const handleFilter = (e: React.MouseEvent) => {
    const value = e.currentTarget.getAttribute("value");
    const titleValue = title.toLowerCase().replace(" ", "_");
    if(setQueryValue && value) {
        setQueryValue({
            [titleValue]: value
        })
    }
}

  return (
    <div className="group relative">
      <button className="bg-gray-200 px-4 py-2 rounded-xl">{title}</button>
      <ul className="hidden w-36 group-hover:inline-block absolute top-10 left-0 bg-gray-100 rounded-xl overflow-hidden">
        {options.map((option) => (
          <li
            key={option}
            value={option}
            className="w-full cursor-pointer px-4 py-2 border-b hover:bg-gray-300"
            onClick={handleFilter}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FilterDropdown;
