import {createContext, useState, useContext} from 'react';
import { QueryValue, QueryContextProps } from '../misc/types';

const QueryContext = createContext<QueryContextProps | null> (null);

export const QueryProvider = ({children}: { children: React.ReactNode }) => {
    const [queryValue, setQueryValue] = useState<QueryValue>({})
    return (
        <QueryContext.Provider value={{queryValue, setQueryValue}}>
            {children}
        </QueryContext.Provider>
    )
}

// export the customized hook so it is easier to use, I dont have to import useContext all the time
export const useQuery = () => {
    const context = useContext(QueryContext);
    if(!context) {
        throw new Error ('SOmething wrong woth query context!')
    }
    return context;
}