import { useEffect, useMemo, useState, useCallback } from "react";

import { useFetchMeta, useFetchBreweries } from "../hooks/useFetchBreweries";
import Filter from "../components/Filter";

import Loading from "../components/Loading";
import Table from "../components/Table";
import { useQuery } from "../hooks/queryContext";
import ErrorPage from "./ErrorPage";
import Contact from "../components/Contact";

function Main() {
  const { queryValue, setQueryValue } = useQuery();

  const { meta, error: metaErr } = useFetchMeta();

  // meta: { total, page, per_page = 50 }
  // here I use useMemo hook, so I don'r have to calculate the totalPage  every re-render, but TBH, I think this calculation is not that expensive
  const totalPage = useMemo(
    () => Math.ceil(parseInt(meta.total) / parseInt(meta.per_page)),
    [meta.total, meta.per_page]
  );

  // console.log(totalPage);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setQueryValue((prev) => ({
      ...prev,
      page: currentPage.toString(),
    }));
  }, [currentPage, setQueryValue]);

  // otherwise the current page will not change after re-render >_<
  useEffect(() => {
    setCurrentPage(1);
  }, [queryValue.by_name, queryValue.by_country, queryValue.by_type]);

  const { data, loading, error: breweryErr } = useFetchBreweries();

  // i use callback here, so it will not re-create the same function every re-render, even though I think this is also not that expensive
  // but any way, it is just a samll project with some simple data, no action is actually expensive

  const handleNext = useCallback(() => {
    if (currentPage < totalPage) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [currentPage, totalPage]);

  const handlePrev = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [currentPage]);

  if (breweryErr || metaErr) {
    return <ErrorPage />;
  }

  return (
    <div className="px-8 pb-16 md:px-16">
      <div className="min-h-screen flex flex-col justify-center items-center gap-4">
        
        <Filter />
        <h2 className="text-2xl self-start">{`List of all breweries ${
          queryValue.by_name ? `with name "${queryValue.by_name}"` : ""
        }`}</h2>
        <div className="self-start w-full flex justify-between items-center">
          <p className="text-xs">{`page ${currentPage} of ${totalPage}`}</p>
          <div className="flex gap-4">
            <button
              className="btn-primary"
              aria-label="Go to previous page"
              onClick={handlePrev}
            >
              Previous
            </button>
            <button
              className="btn-primary"
              aria-label="go to next page"
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        </div>

        {loading ? <Loading /> : <Table data={data} />}

        <div className="self-end flex justify-end gap-4">
          <button
            className="btn-primary"
            aria-label="go to previous page"
            onClick={handlePrev}
          >
            Previous
          </button>
          <button
            className="btn-primary"
            aria-label="go to next page"
            onClick={handleNext}
          >
            Next
          </button>
        </div>

        <Contact />
      </div>
    </div>
  );
}

export default Main;
