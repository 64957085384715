
function Footer() {
  return (
    <div className="bg-yellow-600 h-60 flex flex-col justify-center items-center gap-4 text-white">
      <p>This project is powered by <a className="font-bold text-red-100" href="https://www.openbrewerydb.org/" target="_blank" rel="noreferrer">Open Brewery DB</a> api.</p>
      <p>Maintained by <a className="font-bold text-red-900" href="https://github.com/yuankeMiao" target="_blank" rel="noreferrer">Yuanke</a></p>
    </div>
  )
}

export default Footer