import Root from "./pages/Root";
import Main from "./pages/Main";
import Single from "./pages/Single";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryProvider } from "./hooks/queryContext";

// here I use createBrowserRouter because I thought I will use the loader feature by react-router-dom, but I did not use it eventually.
// i think for my case, the data is not that heavy to load, but meybe i will try to apply it if I have extra time for this assignment.
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/brewery/:id",
        element: <Single />,
      },
    ],
  },
]);

const App = () => {
  return (
    <QueryProvider>
      <div className="bg-yellow-50 min-w-min">
        <RouterProvider router={router} />
      </div>
    </QueryProvider>
  );
};

export default App;
