import { Link } from "react-router-dom";
import Search from "./Search";
import { useQuery } from "../hooks/queryContext";

function Header() {
  const {setQueryValue} = useQuery();

  const handleClickTitle = () => {
    setQueryValue({});
  }

  return (
    <header className="p-6 flex justify-between items-center bg-yellow-400 w-full min-w-min">
      <div>
        <h1 className="text-5xl font-bold text-red-700 min-w-96"><Link to="/" onClick={handleClickTitle} className="drop-shadow-[10px_6px_0px_rgba(0,0,0,0.25)]">Brewery Index</Link></h1>
        <p className="mt-2 font-bold animate-bounce">Don't drink too much!</p>
      </div>
        <Search />
    </header>
  );
}

export default Header;
